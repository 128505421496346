<template>
<ion-page>
  <ion-header :translucent="true">
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button @click="closeModal">CLOSE</ion-button>
      </ion-buttons>
      <ion-title>{{$store.state.clicked}}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content  style=" text-align: center" class="ion-padding">
<div style="background-color:rgb(255,255,255); padding:20px 0px 20px 0px;">
<!-- <img @click="picture" src="https://cdn.icon-icons.com/icons2/2643/PNG/512/male_boy_person_people_avatar_icon_159358.png" width=150 > -->
<img @click="resume" src="https://icons.iconarchive.com/icons/papirus-team/papirus-mimetypes/512/x-office-document-icon.png" width=150>

<h2>{{fullName}}</h2>

<ion-button @click="presentAlert" >Move Forward</ion-button>

<!-- <ion-button style="background-color:white">Send to Contact</ion-button>-->
</div>
<ion-item> 
      <ion-label style='padding:0vw 10vw 0vw 10vw;'>Send to Contact</ion-label>
      <ion-select multiple="true" cancel-text="Cancel" ok-text="Send">
        <ion-select-option value="Mother">Mother: mother@gmail.com</ion-select-option>
        <ion-select-option value="Father">Father: father@gmail.com</ion-select-option>
        <ion-select-option value="peppers">Cousin: father@gmail.com</ion-select-option>
        <ion-select-option value="Friend">Friend: friend@gmail.com</ion-select-option>
      </ion-select>
    </ion-item> 
<div style="padding:20px 30px 20px 30px;">

 <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Esse reiciendis et officiis voluptas, sunt nostrum molestias eos. Laudantium veniam qui velit ea, blanditiis ipsum exercitationem rem ad ipsa asperiores illo.</p>
<br/>

<br/>
</div>

  </ion-content>
</ion-page>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import {
  IonPage,
  IonContent,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButton,
  IonButtons,
  modalController,
  alertController
} from "@ionic/vue";  
import ModalHomeDetailVue from "./ModalHomeDetail.vue";
import pictureVue from "./Picture.vue";
import modalPage from "./Modal.vue";
export default defineComponent({
  name: "ModalHome",
  components: {
    IonPage,
    IonContent,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButton,
    IonButtons,
  },
  props: ['fullName'],
  setup() {
    // the nav ref
    const modalNav = ref(null);

    // get the the ion-nav element so we can make
    // api calls
    onMounted(() => {
      const v = document.getElementById("modal-nav");
      modalNav.value = v;
    });

    /**
     *  when going to the next page, I pass the nav as a property
     * so I dont need to get it from the document again
     */
    const resume = () => {
      modalNav.value.push(ModalHomeDetailVue, {
        // these come across as properties on the component
        modalNav: modalNav
      });
    };

    const picture = () => {
      modalNav.value.push(pictureVue, {
        // these come across as properties on the component
        modalNav: modalNav
      });
    };

    /**
     * close the modal dialog
     */
    const closeModal = async () => {
      await modalController.dismiss();
    };

    return {
      resume,
      picture,
      closeModal
    };
  },
  methods: {
    async presentAlert() {
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: 'Confirmed',
          //subHeader: 'Move Forward',
          message: 'Your Shadchan will contact you within 24hr with details.<br/><br/> Hatzlacha.',
          buttons: ['OK'],
        });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      console.log('onDidDismiss resolved with role', role);
    },
  }
  

});
</script>

<style lang="css" scoped>

</style>